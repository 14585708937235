<template>
<div id="Squares">
  <canvas id="Squares__canvas"></canvas>
</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'Squares',
  setup () {

    onMounted(() => {

      const canvas = document.getElementById('Squares__canvas');

      if (canvas) {
        let ctx = canvas.getContext('2d');

        const squaresContainer = document.getElementById('Squares');
        let _squaresContainer = squaresContainer.getBoundingClientRect();
        let mouse = {
          x: _squaresContainer.width / 2,
          y: _squaresContainer.height / 2,
        };

        if (squaresContainer) {
          squaresContainer.onpointermove = event => {
            let _squaresContainer = squaresContainer.getBoundingClientRect();
            mouse.x = event.x - _squaresContainer.left;
            mouse.y = event.y - _squaresContainer.top;
          };
        }


        function drawSquare (x, y) {
          const xDist = x - mouse.x;
          const yDist = y - mouse.y;
          const distance = Math.sqrt(Math.pow(xDist, 2) + Math.pow(yDist, 2));

          let fraction = 30;
          let centerX = x - (distance / fraction);
          let centerY = y - (distance / fraction);
          let sideSize = distance / (fraction / 2);

          ctx.beginPath();
          ctx.rect(centerX, centerY, sideSize, sideSize);
          ctx.fillStyle = `rgba(255, 255, 255, 0.5)`;
          ctx.fill();
        }


        function init () {
          let container = document.getElementById('Squares__canvas');
          if (container) {
            let _container = container.getBoundingClientRect();
            canvas.width = _container.width;
            canvas.height = _container.height;
            let distance = 40;
            for (let x = 0; x <= canvas.width + distance; x += distance) {
              for (let y = 0; y <= canvas.height + distance; y += distance) {
                drawSquare(x, y);
              }
            }
          }
        }


        // Animation Loop
        function animate () {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          init();

          let squaresDOM = document.getElementById('Squares');
          if (squaresDOM) requestAnimationFrame(animate);
        }

        animate();
      }
    });
  },
};
</script>

<style lang="stylus" scoped>
#Squares
  overflow hidden
  position absolute
  z-index 100
  top 70px
  right 70px
  bottom 70px
  left 70px
  border-radius 20px
  box-shadow 0 10px 30px 0 rgba(0, 0, 0, 0.32)
  background url("../../public/squares/_dark.jpg")
  background-position center
  background-size cover
  #Squares__canvas
    position absolute
    right 0
    top 0
    bottom 0
    left 0
    width 100%
    height 100%
</style>